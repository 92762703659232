<template>
<div class="content">
    <list-header />
    <router-view />
</div>
	
</template>

<script>
import ListHeader from '@/components/ListHeader.vue'
export default {
    components:{
        ListHeader
    },
    mounted(){
        // this.$store.commit({type:'stopAuthenticationLoader'})
        // this.$store.commit({type:'setBranchId',branchId: this.$route.params.id})
    }
}

</script>

